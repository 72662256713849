import { createI18n } from "vue-i18n";
import store from "../store";
const i18n = createI18n({
    legacy: false, // 使用CompotitionAPI必须添加这条.
    locale: store.state.lang || "zh-CN",
    fallbackLocale: store.state.lang || "zh-CN",
    messages: {
        "zh-CN": require("../locals/zh.js").default,
        "en-US": require("../locals/en.js").default,
        zht: require("../locals/zht.js").default,
    },
});

export default i18n;
