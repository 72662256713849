import { showToast } from "vant";
import "vant/es/toast/style";

//封装更改toast默认值
export const myVanToast = (options) => {
    showToast({
        type: options.type ? options.type : "text",
        message: options.message,
        duration: options.duration >= 0 ? options.duration : 2000,
        position: options.position ? options.position : "middle",
    });
    return showToast;
};
