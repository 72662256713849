const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home"),
    },
];
export default routes;
