export default {
    header: {
        title1: "GAMES",
        title2: "APPS",
        title3: "SHOP",
    },
    home: {
        text1_h5: "The future<br/>of AI-generated games",
        text1_pc: "The future of<br/>AI-generated games",
        text2: "We're making AI a tool of creativity and freedom for everyone.",
        text3_h5: "Infinite creativity, brought to life<br/>by AI",
        text3_pc: "Infinite creativity, brought to life by AI",
        text4: "We're building a place to discover, explore and create in the first ecosystem of AI collaboration and creativity.",
        text5: "GAMES",
        text6: "Explore a new era of AI-powered gaming.",
        text7: "COMING SOON!",
        text8: "Our first AI installment. You enter your actions and the AI will continue the story. PLAY FREE",
        text9: "COMING SOON!",
        text10: "ABOUT",
        text11: "SUPE.AI is a creator of new worlds with a mission to enable vast and living AI-powered worlds for people to explore. We make worlds that are an order of magnitude more interesting and more immersive because they are dynamic, built on the magic of AI technology, and have endless possibilities.",
        text12: "Through the development of our tools, products, and platform, we will bring a greater understanding of AI’s capabilities to the world while creating compelling entertainment experiences where people enjoy spending their time.",
        text13: "AI is the magic of SUPE!",
        text14: "Join us on the adventure",
        text15: " We’re trying to do something big, something that’s never been done before…and we love it. We feel most alive when we’re conquering giants, slaying dragons, and swimming in blue ocean.",
    },
};
